import {
    DENSITY_DATA,
  DENSITYCLASS_DATA,
    DENSITY_ADD_DATA,
    DENSITY_UPDATE_DATA,
    DENSITY_GET_DATA,
    DENSITY_DELETE_DATA,
  } from "./Constants";
  
  //For listing
  const loadDensityDataRequest = () => ({
    type: DENSITY_DATA.DENSITY_DATA_LOAD,
  });
  const setDensityDataSuccess = (densities) => ({
    type: DENSITY_DATA.DENSITY_DATA_SUCCESS,
    densities,
  });
  const setDensityDataFail = (error) => ({
    type: DENSITY_DATA.DENSITY_DATA_FAIL,
    error,
  });
  
//For listing with CLass Category
const loadDensityClassDataRequest = () => ({
  type: DENSITYCLASS_DATA.DENSITYCLASS_DATA_LOAD,
});
const setDensityClassDataSuccess = (densityClass) => ({
  type: DENSITYCLASS_DATA.DENSITYCLASS_DATA_SUCCESS,
  densityClass,
});
const setDensityClassDataFail = (error) => ({
  type: DENSITYCLASS_DATA.DENSITYCLASS_DATA_FAIL,
  error,
});
  //For add
  const loadDensityAddDataRequest = (request) => ({
    type: DENSITY_ADD_DATA.DENSITY_ADD_DATA_LOAD,
    request,
  });
  const setDensityAddDataSuccess = (density) => ({
    type: DENSITY_ADD_DATA.DENSITY_ADD_DATA_SUCCESS,
    density,
  });
  
  const setDensityAddDataFail = (error) => ({
    type: DENSITY_ADD_DATA.DENSITY_ADD_DATA_FAIL,
    error,
  });
  
  //For Edit
  const loadDensityUpdateDataRequest = (request) => ({
    type: DENSITY_UPDATE_DATA.DENSITY_UPDATE_DATA_LOAD,
    request,
  });
  
  const setDensityUpdateDataSuccess = (density) => ({
    type: DENSITY_UPDATE_DATA.DENSITY_UPDATE_DATA_SUCCESS,
    density,
  });
  
  const setDensityUpdateDataFail = (error) => ({
    type: DENSITY_UPDATE_DATA.DENSITY_UPDATE_DATA_FAIL,
    error,
  });
  
  //To Get
  const loadDensityGetDataRequest = (id) => ({
    type: DENSITY_GET_DATA.DENSITY_GET_DATA_LOAD,
    id,
  });
  
  const setDensityGetDataSuccess = (density) => ({
    type: DENSITY_GET_DATA.DENSITY_GET_DATA_SUCCESS,
    density,
  });
  
  const setDensityGetDataFail = (error) => ({
    type: DENSITY_GET_DATA.DENSITY_GET_DATA_FAIL,
    error,
  });
  
  //Delete
  const loadDensityDeleteDataRequest = (request) => ({
    type: DENSITY_DELETE_DATA.DENSITY_DELETE_DATA_LOAD,
    request,
  });
  
  const setDensityDeleteDataSuccess = (density) => ({
    type: DENSITY_DELETE_DATA.DENSITY_DELETE_DATA_SUCCESS,
    density,
  });
  
  const setDensityDeleteDataFail = (error) => ({
    type: DENSITY_DELETE_DATA.DENSITY_DELETE_DATA_FAIL,
    error,
  });
  
  export {
    loadDensityDataRequest,
    setDensityDataSuccess,
    setDensityDataFail,
  loadDensityClassDataRequest,
  setDensityClassDataSuccess,
  setDensityClassDataFail,
  loadDensityAddDataRequest,
  setDensityAddDataSuccess,
  setDensityAddDataFail,
  loadDensityUpdateDataRequest,
  setDensityUpdateDataSuccess,
  setDensityUpdateDataFail,
  loadDensityGetDataRequest,
  setDensityGetDataSuccess,
  setDensityGetDataFail,
  loadDensityDeleteDataRequest,
  setDensityDeleteDataSuccess,
  setDensityDeleteDataFail,
};
