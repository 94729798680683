import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState, useRef } from "react";
import { Card,Button,Collapse,  Col, Row } from "react-bootstrap";
import Breadcrumbs from "../../../shared/components/Breadcrumbs"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import ListView from "../../../shared/components/listview/ListView"
import { loadTeamPlanGetDataRequest, loadPlanByEmpLocPerGetDataRequest } from "../redux/Actions"
import Modalpopup from "../../../shared/components/Modalpopup"
import FormError from "../../../shared/components/FormError"
import MyPlanView from "./MyPlanView"
import GeneratePDF from "./GeneratePDF";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Loader from '../../../shared/components/Loader';

//import { CANCEL, DIVISION, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, HIRE_STATUS, HOURLY_SALARY, JOB_TITLE, MARKET, PART_FULL_TIME, REGION, SAVE, STORE } from "../../../shared/constants/Constants";

const TeamPriority = () => {
    const dispatch = useDispatch();
    const formik = useFormik({});   
    const getQuarter = (date) => {
        const month = date.getMonth() + 1; 
        if (month >= 1 && month <= 3) {
          return 'Q1';
        } else if (month >= 4 && month <= 6) {
          return 'Q2';
        } else if (month >= 7 && month <= 9) {
          return 'Q3';
        } else {
          return 'Q4';
        }
      };
   
    let currentYear = new Date().getFullYear();
    let currentQuarter = getQuarter(new Date());

    let { teamplans, myplan } = useSelector((state) => state.myPlans);
    let [errorMessage, setErrorMessage] = useState(false);
    
    console.log("Hope",teamplans);
    let [showEditModal, setShowEditModal] = useState(false)
    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [selectedPlan, setSelectedPlan] = useState({});
    let [selectedDownloadPlan, setSelectedDownloadPlan] = useState({});    
    let [clickViewFunc, setClickViewFunc] = useState(null);
    let [clickDownloadFunc, setClickDownloadFunc] = useState(null);    
    let [selectedYear, setSelectedYear] =  useState(currentYear);
    let [selectedQuarter, setSelectedQuarter] =  useState(currentQuarter);
    console.log("selectedQuarter 51",selectedQuarter);
    let test = selectedQuarter;
    console.log("test 51",test);
    let [downloadBlock, setDownloadBlock] =  useState(false);
    const selectedDownloadPlanRef = useRef(null);
    const [isDownload, setIsDownload] = useState(false);

    const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true,
            pinned:'left',
        },
        {
            display_name: "Market Name",
            field: "market_name",
            type: "text",
        } ,
        {
            display_name: "Start Date",
            field: "start_date",
            type: "text"
        },
        {
            display_name: "End Date",
            field: "end_date",
            type: "text"
        },
        {
            display_name: "Manager",
            field: "full_name",
            type: "text"
        },
        {
            display_name: "Job Title",
            field: "job_title",
            type: "text"
        },  
        {
            display_name: "Current Manager",
            field: "current_manager",
            type: "text"
        },       
        {
            display_name: "Progress",
            field: 'progress',
            type: "progressbar",
            width: 100
        },
        {
            display_name: 'Actions',
            field: 'actions',
            hide: false
        },
    ]

    
    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }

    const handleEdit = (values) => {
        //dispatch(loadEmployeeTypeTrackerEditDataRequest(values))
        setShouldCloseModal(true);
    }

    useEffect(() => {        
        setClickViewFunc(
            () => (row) => {
                setSelectedPlan(row);
                setDownloadBlock(false);
                console.log("Hai   view",row);
                setShowEditModal(true);
            })   
    }, [])

  /*  useEffect(() => {
        //console.log("Selected Period sabar", selectedQuarter);
        //let test = selectedQuarter;
        //console.log("Selected Period test 130", test);

        setClickDownloadFunc(
            () => (row) => {
                //console.log("Selected Period sai", selectedQuarter);
                console.log("Selected Period test 51", test);

                setDownloadBlock(true);
                setSelectedDownloadPlan(row);
                selectedDownloadPlanRef.current = row;
                console.log("row", row); 
                let request = {
                    "employee_id": row['employee_id'],
                    "location_id": row['loc_id'],
                    "period": selectedQuarter,
                    "year": selectedYear
                }
                dispatch(loadPlanByEmpLocPerGetDataRequest(request));            
                
            });
                
    }, [dispatch, selectedQuarter, selectedYear]);
    //console.log('Sabar', myplan); */

    

    

    
    useEffect(() => {        
        setClickDownloadFunc(() => (row) => {
                setDownloadBlock(true);
                setSelectedDownloadPlan(row);
                selectedDownloadPlanRef.current = row; 
                console.log('Testing',selectedDownloadPlanRef.current);  
               setTimeout(() => {
                   const input = document.getElementById("download-content");
                    setDownloadBlock(true);
                    html2canvas(input, { scale: 2 }).then((canvas) => {                        
                        const imgData = canvas.toDataURL("image/jpeg", 0.8); // compress image
                        const pdf = new jsPDF('p', 'pt', 'a4');
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = pdf.internal.pageSize.getHeight();
                        const imgProps = pdf.getImageProperties(imgData);
                        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        let heightLeft = imgHeight;
                        let position = 0;
                        pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
                        heightLeft -= pdfHeight;
                        while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            pdf.addPage();
                            pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
                            heightLeft -= pdfHeight;
                        }
                        let fileName = selectedDownloadPlanRef.current["full_name"] + "_" + selectedYear + ".pdf";
                        pdf.save(fileName);
                        setDownloadBlock(false);

                    });
                }, 1000); 
            })   
    }, []) 
    

    useEffect(() => {
        console.log("loading team priority")
        console.log("selectedQuarter 196",selectedQuarter);

        let request = {
            year:selectedYear,
            quarter:selectedQuarter
        }
        
        dispatch(loadTeamPlanGetDataRequest(request));     
         // setInitialValues(myplan)  
    }, [dispatch]);

    let staticYears = [];
	for (var i = 2024; i <= currentYear; i++) {
		let obj = {};
		obj["name"] = i;
		obj["value"] = i;
		staticYears.push(obj)
	}

    const changeSelectedYear = (value) => {
		setSelectedYear(value);
	}

    const changeSelectedQuarter = (value) => {
        console.log("selectedQuarter 220",selectedQuarter);

		setSelectedQuarter(value);
	}
    const searchByYrandQuarter = () => {
		console.log(selectedYear, selectedQuarter )
        let request = {
            year:selectedYear,
            quarter:selectedQuarter
        }
        dispatch(loadTeamPlanGetDataRequest( request));     
	}
    

  const rowClassRules = {
    'completed-row': (params) => params.data.end_date != '2099-12-31',
  };
        
    console.log("setSelectedQuarter", selectedQuarter);
    return <>
        <Breadcrumbs children={[{ label: "Team Plan" }]} />       
        <div className="d-flex justify-content-end"  style={{ width:'100%' }}>
            <div className="mb-1"> 
                <select  className=" mx-1  year-select" style={{ height:'33px', width: '100px' }} onChange={(e) => {
                    changeSelectedYear(e.target.value) }}>
                    {staticYears.map((year) =>
                        year.value == currentYear ? <option key={year.value} value={year.value} selected >{year.name}</option> :
                            <option key={year.value} value={year.value} >{year.name}</option>
                    )}
                </select>

                <select className=" mx-1  year-select" style={{ height:'33px', width: '100px' }}  onChange={(e) => {
                    changeSelectedQuarter(e.target.value) }}>
                    {['Q1','Q2','Q3','Q4'].map((quarter) =>
                        quarter == currentQuarter ? <option key={quarter} value={quarter} selected >{quarter}</option> :
                            <option key={quarter} value={quarter} >{quarter}</option>
                    )}
                </select>
                <DefaultButton
                    buttonClass={"btn btn-secondary default-btn-color"}
                    buttonTitle={"Search"}
                    buttonType={"submit"}
                    actionBlock={() => {
                        searchByYrandQuarter();
                    }}                                               
                
                />
            </div>   
        </div>
        <br/>
               
        {/* <select value={selectedCompanyId} style={{ width: 250 }} className="float-end form-control" 
                onChange={(e) => { setSelectedCompanyId(Number.parseInt(e.target.value)) }}>
            <option value={0}>{ALL_COMPANIES}</option>
            {companies.map((company) =>
                <option key={company.id} value={company.id} >{company.company_name}</option>
            )
            }
        </select>       */}
        {isDownload && <Loader />}
        <ListView            
            columnNames={columnDefs}
            rowData={teamplans}  
            clickView={clickViewFunc}    
            clickDownload={clickDownloadFunc}    
            pact = {true}         
        />
        
        
        <Modalpopup show={showEditModal} actionTitle="View Plan" handleClose={closeEditModal} size={"lg"}>
            <FormError>
                {errorMessage}
            </FormError>
            <MyPlanView  submit={handleEdit}cancel={closeEditModal} plan={selectedPlan} period={selectedQuarter} year = {selectedYear} />
        </Modalpopup>

        <div id='download-content' style={{ padding:'40px', fontSize:'2rem' , display: downloadBlock ? 'block' : 'none' }} >
            <div >
                <MyPlanView plan={selectedDownloadPlan} period={selectedQuarter} year = {selectedYear} view='download' />
            </div> 
        </div>
       {/* <div id="generate-pdf-btn">
        <GeneratePDF plans={selectedDownloadPlan ? [selectedDownloadPlan] : []} period={selectedQuarter} year = {selectedYear} view='download' isDownload= {downloadBlock} />

        </div>*/}
    </>
}

export default TeamPriority;
