import {
  DENSITY_DATA,
  DENSITYCLASS_DATA,
  DENSITY_ADD_DATA,
  DENSITY_UPDATE_DATA,
  DENSITY_GET_DATA,
  DENSITY_DELETE_DATA,
} from "./Constants";

const initialState = {
  loading: false,
  error: null,
  densities: [],
  densityClass: [],
  density: {},
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case DENSITY_DATA.DENSITY_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        densities: [],
      };
    case DENSITY_DATA.DENSITY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        densities: action.densities,
        error: null,
      };
    case DENSITY_DATA.DENSITY_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        densities: [],
      };

      case DENSITYCLASS_DATA.DENSITYCLASS_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
          densityClass: [],
        };
      case DENSITYCLASS_DATA.DENSITYCLASS_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          densityClass: action.densityClass,
          error: null,
        };
      case DENSITYCLASS_DATA.DENSITYCLASS_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
          densityClass: [],
        };  

      //Add
      case DENSITY_ADD_DATA.DENSITY_ADD_DATA_LOAD:
        return {
          ...state,
          loading: true,
          density: {},
          error: null,
        };
      case DENSITY_ADD_DATA.DENSITY_ADD_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          density: action.density,
          error: null,
        };
      case DENSITY_ADD_DATA.DENSITY_ADD_DATA_FAIL:
        return {
          ...state,
          loading: false,
          density: {},
          error: action.error,
        };
      //Get
      case DENSITY_GET_DATA.DENSITY_GET_DATA_LOAD:
        return {
          ...state,
          loading: true,
          density: {},
          error: null,
        };
      case DENSITY_GET_DATA.DENSITY_GET_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          density: action.density,
          error: null,
        };
      case DENSITY_GET_DATA.DENSITY_GET_DATA_FAIL:
        return {
          ...state,
          loading: false,
          density: {},
          error: action.error,
        };
      //Update
      case DENSITY_UPDATE_DATA.DENSITY_UPDATE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          density: {},
          error: null,
        };
      case DENSITY_UPDATE_DATA.DENSITY_UPDATE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          density: action.density,
          error: false,
        };
      case DENSITY_UPDATE_DATA.DENSITY_UPDATE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          density: {},
          error: action.error,
        };
      //Delete
      case DENSITY_DELETE_DATA.DENSITY_DELETE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          density: {},
          error: null,
        };
      case DENSITY_DELETE_DATA.DENSITY_DELETE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          density: action.density,
          error: null,
        };
      case DENSITY_DELETE_DATA.DENSITY_DELETE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          density: {},
          error: action.error,
        };
      default:
        return {
          ...state,
        };
    }
  };
  
  export default Reducer;
  