import {
  MYPLAN_GET_DATA, 
  MYPLAN_ADD_DATA, 
  MYPLAN_UPDATE_DATA,  
  MYPLAN_DELETE_DATA,
  MYPLANMGRNOTE_ADD_DATA,
  MYPLANNOTE_ADD_DATA ,
  MYPLANNOTE_DELETE_DATA ,
  TEAMPLAN_GET_DATA  ,
  MYLOC_GET_DATA,
  MYPLAN_EMP_LOC_PER_GET_DATA,
  PLAN_BY_EMP_GET_DATA,
  PLAN_BY_LOC_LEVEL_GET_DATA,
  PRIORITY_BY_ID_GET_DATA,
  INSERT_T0_NEWQUARTER_ADD_DATA
  
} from "./Constants";

//For listing
// const loadMyPlanDataRequest = () => ({
//   type: MYPLAN_DATA.MYPLAN_DATA_LOAD,
// });
// const setMyPlanDataSuccess = (myplans) => ({
//   type: MYPLAN_DATA.MYPLAN_DATA_SUCCESS,
//   myplans,
// });
// const setMyPlanDataFail = (error) => ({
//   type: MYPLAN_DATA.MYPLAN_DATA_FAIL,
//   error,
// });

//To Get
const loadMyPlanGetDataRequest = (request) => ({
  type: MYPLAN_GET_DATA.MYPLAN_GET_DATA_LOAD,
  request,
});

const setMyPlanGetDataSuccess = (myplan) => ({
  type: MYPLAN_GET_DATA.MYPLAN_GET_DATA_SUCCESS,
  myplan,
});

const setMyPlanGetDataFail = (error) => ({
  type: MYPLAN_GET_DATA.MYPLAN_GET_DATA_FAIL,
  error,
});

//To Get Priority by Id
const loadMyPrioritybyIdDataRequest = (request) => ({
  type: PRIORITY_BY_ID_GET_DATA.PRIORITY_BY_ID_GET_DATA_LOAD,
  request,
});

const setMyPrioritybyIdDataSuccess = (myplan) => ({
  type: PRIORITY_BY_ID_GET_DATA.PRIORITY_BY_ID_GET_DATA_SUCCESS,
  myplan,
});

const setMyPrioritybyIdDataFail = (error) => ({
  type: PRIORITY_BY_ID_GET_DATA.PRIORITY_BY_ID_GET_DATA_FAIL,
  error,
});


//To Get my Location
const loadMyLocGetDataRequest = (id) => ({
  type: MYLOC_GET_DATA.MYLOC_GET_DATA_LOAD,
  id,
});

const setMyLocGetDataSuccess = (myplan) => ({
  type: MYLOC_GET_DATA.MYLOC_GET_DATA_SUCCESS,
  myplan,
});

const setMyLocGetDataFail = (error) => ({
  type: MYLOC_GET_DATA.MYLOC_GET_DATA_FAIL,
  error,
});


//To Get team priority
const loadTeamPlanGetDataRequest = (request) => ({
  type: TEAMPLAN_GET_DATA.TEAMPLAN_GET_DATA_LOAD,
  request
});

const setTeamPlanGetDataSuccess = (myplan) => ({
  type: TEAMPLAN_GET_DATA.TEAMPLAN_GET_DATA_SUCCESS,
  myplan,
});

const setTeamPlanGetDataFail = (error) => ({
  type: TEAMPLAN_GET_DATA.TEAMPLAN_GET_DATA_FAIL,
  error,
});

//To Get All priority By Location
const loadPlanByLocLevelGetDataRequest = (request) => ({
  type: PLAN_BY_LOC_LEVEL_GET_DATA.PLAN_BY_LOC_LEVEL_GET_DATA_LOAD,
  request
});

const setPlanByLocLevelGetDataSuccess = (myplan) => ({
  type: PLAN_BY_LOC_LEVEL_GET_DATA.PLAN_BY_LOC_LEVEL_GET_DATA_SUCCESS,
  myplan,
});

const setPlanByLocLevelGetDataFail = (error) => ({
  type: PLAN_BY_LOC_LEVEL_GET_DATA.PLAN_BY_LOC_LEVEL_GET_DATA_FAIL,
  error,
});



//For add
const loadMyPlanAddDataRequest = (request) => ({
  type: MYPLAN_ADD_DATA.MYPLAN_ADD_DATA_LOAD,
  request,
});
const setMyPlanAddDataSuccess = (myplan) => ({
  type: MYPLAN_ADD_DATA.MYPLAN_ADD_DATA_SUCCESS,
  myplan,
});

const setMyPlanAddDataFail = (error) => ({
  type: MYPLAN_ADD_DATA.MYPLAN_ADD_DATA_FAIL,
  error,
});

//tO COPY PLAN TO NEW QUATER
const loadCopyPlantoNewQuarterAddDataRequest = (request) => ({
  type: INSERT_T0_NEWQUARTER_ADD_DATA.INSERT_T0_NEWQUARTER_ADD_DATA_LOAD,
  request,
});
const setCopyPlantoNewQuarterAddDataSuccess = (myplan) => ({
  type: INSERT_T0_NEWQUARTER_ADD_DATA.INSERT_T0_NEWQUARTER_ADD_DATA_SUCCESS,
  myplan,
});

const setCopyPlantoNewQuarterAddDataFail = (error) => ({
  type: INSERT_T0_NEWQUARTER_ADD_DATA.INSERT_T0_NEWQUARTER_ADD_DATA_FAIL,
  error,
});


//For add notes
const loadMyPlanMgrNoteAddDataRequest = (request) => ({
  type: MYPLANMGRNOTE_ADD_DATA.MYPLANMGRNOTE_ADD_DATA_LOAD,
  request,
});
const setMyPlanMgrNoteAddDataSuccess = (myplan) => ({
  type: MYPLANMGRNOTE_ADD_DATA.MYPLANMGRNOTE_ADD_DATA_SUCCESS,
  myplan,
});
const setMyPlanMgrNoteAddDataFail = (error) => ({
  type: MYPLANMGRNOTE_ADD_DATA.MYPLANMGRNOTE_ADD_DATA_FAIL,
  error,
});

//For add notes
const loadMyPlanNoteAddDataRequest = (request) => ({
  type: MYPLANNOTE_ADD_DATA.MYPLANNOTE_ADD_DATA_LOAD,
  request,
});
const setMyPlanNoteAddDataSuccess = (myplan) => ({
  type: MYPLANNOTE_ADD_DATA.MYPLANNOTE_ADD_DATA_SUCCESS,
  myplan,
});
const setMyPlanNoteAddDataFail = (error) => ({
  type: MYPLANNOTE_ADD_DATA.MYPLANNOTE_ADD_DATA_FAIL,
  error,
});

//For DELETE notes
const loadMyPlanNoteDeleteDataRequest = (request) => ({
  type: MYPLANNOTE_DELETE_DATA.MYPLANNOTE_DELETE_LOAD,
  request,
});
const setMyPlanNoteDeleteDataSuccess = (myplan) => ({
  type: MYPLANNOTE_DELETE_DATA.MYPLANNOTE_DELETE_SUCCESS,
  myplan,
});

const setMyPlanNoteDeleteDataFail = (error) => ({
  type: MYPLANNOTE_DELETE_DATA.MYPLANNOTE_DELETE_FAIL,
  error,
});



//For Edit
const loadMyPlanUpdateDataRequest = (request) => ({
  type: MYPLAN_UPDATE_DATA.MYPLAN_UPDATE_DATA_LOAD,
  request,
});

const setMyPlanUpdateDataSuccess = (myplan) => ({
  type: MYPLAN_UPDATE_DATA.MYPLAN_UPDATE_DATA_SUCCESS,
  myplan,
});

const setMyPlanUpdateDataFail = (error) => ({
  type: MYPLAN_UPDATE_DATA.MYPLAN_UPDATE_DATA_FAIL,
  error,
});

//Delete MYPLAN
const loadMyPlanDeleteDataRequest = (request) => ({
  type: MYPLAN_DELETE_DATA.MYPLAN_DELETE_DATA_LOAD,
  request,
});

const setMyPlanDeleteDataSuccess = (myplan) => ({
  type: MYPLAN_DELETE_DATA.MYPLAN_DELETE_DATA_SUCCESS,
  myplan,
});

const setMyPlanDeleteDataFail = (error) => ({
  type: MYPLAN_DELETE_DATA.MYPLAN_DELETE_DATA_FAIL,
  error,
});



//To Get individual team priority by emp, loc and period
const loadPlanByEmpLocPerGetDataRequest = (request) => ({
  type: MYPLAN_EMP_LOC_PER_GET_DATA.MYPLAN_EMP_LOC_PER_GET_DATA_LOAD,
  request
});

const setPlanByEmpLocPerGetDataSuccess = (myplan) => ({
  type: MYPLAN_EMP_LOC_PER_GET_DATA.MYPLAN_EMP_LOC_PER_GET_DATA_SUCCESS,
  myplan,
});

const setPlanByEmpLocPerGetDataFail = (error) => ({
  type: MYPLAN_EMP_LOC_PER_GET_DATA.MYPLAN_EMP_LOC_PER_GET_DATA_FAIL,
  error,
});


//To Get All plan by employee_id 
const loadAllPlanByEmpGetDataRequest = (id) => ({
  type: PLAN_BY_EMP_GET_DATA.PLAN_BY_EMP_GET_DATA_LOAD,
  id
});

const setAllPlanByEmpGetDataSuccess = (myplan) => ({
  type: PLAN_BY_EMP_GET_DATA.PLAN_BY_EMP_GET_DATA_SUCCESS,
  myplan,
});

const seAllPlanByEmpGetDataFail = (error) => ({
  type: PLAN_BY_EMP_GET_DATA.PLAN_BY_EMP_GET_DATA_FAIL,
  error,
});



export {
  // loadMyPlanDataRequest,
  // setMyPlanDataSuccess,
  // setMyPlanDataFail,
  loadMyPlanAddDataRequest,
  setMyPlanAddDataSuccess,
  setMyPlanAddDataFail,

  loadMyPlanUpdateDataRequest,
  setMyPlanUpdateDataSuccess,
  setMyPlanUpdateDataFail,
  
  loadMyPrioritybyIdDataRequest,
  setMyPrioritybyIdDataSuccess,
  setMyPrioritybyIdDataFail,

  loadMyPlanGetDataRequest,
  setMyPlanGetDataSuccess,
  setMyPlanGetDataFail,

  loadMyPlanDeleteDataRequest,
  setMyPlanDeleteDataSuccess,
  setMyPlanDeleteDataFail,
  
  loadMyPlanMgrNoteAddDataRequest,
  setMyPlanMgrNoteAddDataSuccess,
  setMyPlanMgrNoteAddDataFail,

  loadMyPlanNoteAddDataRequest,
  setMyPlanNoteAddDataSuccess,
  setMyPlanNoteAddDataFail,

  loadMyPlanNoteDeleteDataRequest,
  setMyPlanNoteDeleteDataSuccess,
  setMyPlanNoteDeleteDataFail,

  loadTeamPlanGetDataRequest,
  setTeamPlanGetDataSuccess,
  setTeamPlanGetDataFail,

  loadPlanByLocLevelGetDataRequest,
  setPlanByLocLevelGetDataSuccess,
  setPlanByLocLevelGetDataFail,

  loadMyLocGetDataRequest,
  setMyLocGetDataSuccess,
  setMyLocGetDataFail,

  loadPlanByEmpLocPerGetDataRequest,
  setPlanByEmpLocPerGetDataSuccess,
  setPlanByEmpLocPerGetDataFail,
  
  loadAllPlanByEmpGetDataRequest,
  setAllPlanByEmpGetDataSuccess,
  seAllPlanByEmpGetDataFail,
  
  loadCopyPlantoNewQuarterAddDataRequest,
  setCopyPlantoNewQuarterAddDataSuccess,
  setCopyPlantoNewQuarterAddDataFail,
};
