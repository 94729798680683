import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState, useRef } from "react";
import { Card,Button,Collapse,  Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import ListView from "../../../shared/components/listview/ListView"
import { loadMyLocGetDataRequest, loadPlanByEmpLocPerGetDataRequest} from "../redux/Actions"
import Modalpopup from "../../../shared/components/Modalpopup"
import FormError from "../../../shared/components/FormError"
import MyPlanView from "./MyPlanView"
import Breadcrumbs from "../../../shared/components/Breadcrumbs"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import GeneratePDF from "./GeneratePDF";

//import { CANCEL, DIVISION, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, HIRE_STATUS, HOURLY_SALARY, JOB_TITLE, MARKET, PART_FULL_TIME, REGION, SAVE, STORE } from "../../../shared/constants/Constants";

const MyPlan = () => {
    

    const getQuarter = (date) => {
        const month = date.getMonth() + 1; 
        if (month >= 1 && month <= 3) {
          return 'Q1';
        } else if (month >= 4 && month <= 6) {
          return 'Q2';
        } else if (month >= 7 && month <= 9) {
          return 'Q3';
        } else {
          return 'Q4';
        }
      };
      
    let currentYear = new Date().getFullYear();
    let currentQuarter = getQuarter(new Date());
    const dispatch = useDispatch();
    const formik = useFormik({});   
    let navigate = useNavigate();
    let { myloc} = useSelector((state) => state.myPlans);
    let [errorMessage, setErrorMessage] = useState(false);
    const selectedDownloadPlanRef = useRef(null);
    let empInfo = JSON.parse(localStorage.getItem("employee"));

    let [showEditModal, setShowEditModal] = useState(false)
    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [selectedPlan, setSelectedPlan] = useState({});
    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickViewFunc, setClickViewFunc] = useState(null);
    let [clickDownloadFunc, setClickDownloadFunc] = useState(null);
    let [downloadBlock, setDownloadBlock] =  useState(false);
    let [selectedDownloadPlan, setSelectedDownloadPlan] = useState({});
    let [selectedYear, setSelectedYear] =  useState(currentYear);
    let [selectedQuarter, setSelectedQuarter] =  useState(currentQuarter);
    let actionsPermission = true;
    

    const columnDefs = [
        {
            display_name: "Id",
            field: "loc_id",
            type: "number",
            hide: true,
        },
        {
            display_name: (empInfo?.job_title_name == 'Area Vice President') ?  'Region' :  "Market",
            field: "location_name",
            type: "text"
        },
        {
            display_name: "Period",
            field: "period",
            type: "text"
        },
        {
            display_name: "Is Covering",
            field: "is_covering",
            type: "icon",
            icon: "checkbox",
            width: 80,
        },
        {
            display_name: "Progress",
            field: 'progress',
            type: "progressbar",
            width: 100
        },
        {
            display_name: 'Actions',
            field: 'actions',
            hide: false
        }
        // ,
        // {
        //     display_name: "Actions",
        //     field: 'loc_id',
        //     type: "hyperlink",
        //     redirect_link: "/myplan/form/",
        //     redirect_value: "loc_id"
        // }
        
    ]

    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }

    const handleEdit = (values) => {
        //dispatch(loadEmployeeTypeTrackerEditDataRequest(values))
        setShouldCloseModal(true);
    }

    useEffect(() => {        
        setClickEditFunc(
            () => (row) => {
                console.log("row edit",row)
                setSelectedPlan(row);
                //setShowEditModal(true);
                navigate('/myplan/form/'+row['loc_id'], { state: { row } })
            })   
    }, [])
    
    useEffect(() => {
        console.log("loading team priority")
        dispatch(loadMyLocGetDataRequest('135205'));     
         // setInitialValues(myplan)  
      }, [dispatch]);
    console.log("myloc", myloc)

    useEffect(() => {        
        setClickViewFunc(
            () => (row) => {
                console.log("row", row)
                setSelectedPlan(row);
                setDownloadBlock(false);
                setShowEditModal(true);
            })   
    }, [])

    useEffect(() => {
        setClickDownloadFunc(
            () => async (row) => {
                setDownloadBlock(true);
                setSelectedDownloadPlan(row);
                let request = {
                    "employee_id": row['employee_id'],
                    "location_id": row['loc_id'],
                    "period": row['period'],
                    "year": row['year']
                }
              dispatch(loadPlanByEmpLocPerGetDataRequest(request));
              setDownloadBlock(true);
              
                
            });
                
    }, [dispatch, selectedQuarter, selectedYear]); 

  /*  useEffect(() => {        
        setClickDownloadFunc(() => (row) => {
                setDownloadBlock(true);
                setSelectedDownloadPlan(row);
                selectedDownloadPlanRef.current = row; 
                console.log('Testing',selectedDownloadPlanRef.current);  
               setTimeout(() => {
                   const input = document.getElementById("download-content");
                    setDownloadBlock(true);
                    html2canvas(input, { scale: 2 }).then((canvas) => {                        
                        const imgData = canvas.toDataURL("image/jpeg", 0.8); // compress image
                        const pdf = new jsPDF('p', 'pt', 'a4');
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = pdf.internal.pageSize.getHeight();
                        const imgProps = pdf.getImageProperties(imgData);
                        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        let heightLeft = imgHeight;
                        let position = 0;
                        pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
                        heightLeft -= pdfHeight;
                        while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            pdf.addPage();
                            pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
                            heightLeft -= pdfHeight;
                        }
                        let fileName = selectedDownloadPlanRef.current["full_name"] + "_" + selectedDownloadPlanRef.current["period"] +"_" + selectedDownloadPlanRef.current["year"] + ".pdf";
                        pdf.save(fileName);
                        setDownloadBlock(false);

                    });
                }, 1000); 
            })   
    }, []) */



    return <>       
        <Breadcrumbs children={[{ label: "My Plan" }]} />
        <ListView            
            columnNames={columnDefs}
            rowData={myloc} 
            clickEdit={clickEditFunc}
            clickView={clickViewFunc}   
            clickDownload={clickDownloadFunc} 
            pact = {true} 
        />    
        <Modalpopup show={showEditModal} actionTitle="View Plan" handleClose={closeEditModal} size={"lg"}>
                <FormError>
                    {errorMessage}
                </FormError>
            <MyPlanView  submit={handleEdit}cancel={closeEditModal} plan={selectedPlan} period={'Q3'} year = {'2024'} view = {'history'} />
            </Modalpopup>
             {/*<div id='download-content' style={{ padding:'40px', fontSize:'2rem' , display: downloadBlock ? 'block' : 'none' }} >
                <div >
                    <MyPlanView plan={selectedDownloadPlan} period={'Q3'} year = {'2024'} view = {'history'}/>
                </div>
            </div>*/}
            <div id="generate-pdf-btn">
            <GeneratePDF plans={selectedDownloadPlan ? [selectedDownloadPlan] : []} view='download' isDownload= {downloadBlock}  />
            </div>
           
    </>
}

export default MyPlan;
